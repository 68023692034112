import React, { useState, useReducer } from "react";
import useMapContext from "./hooks/useMapContext";
import {
  MapProvider,
  RealTimeStatsContext,
  RealTimeStatsDispatchContext,
} from "./Context";
import { GroupProvider } from "./Group";
import { AuthContext } from "stores/AuthContext";
import Sidebar from "./Sidebar";
import { Container } from "@mui/material";
import { AutocompleteSearch } from "tools/AutocompleteSearch";
import ContextMenu from "./elements/ContextMenu";
import Markers from "./markers";
import MapRoutes from "./routes";
import useMapInit from "./hooks/useMapInit";
import { MapRefs } from "./types";
import { BrowserRouter as Router } from "react-router-dom";
import Campgrounds from "./elements/campgrounds/Campgrounds";
import RealTimeButton from "./elements/RealTimeButton";
import Waypoints from "./elements/waypoints/Waypoints";
import AvoidAreas from "tools/AvoidArea/mapElements/AvoidAreas";
import Incidents from "./elements/incidents/Incidents";
import BusZones from "tools/BusZones/mapElements/BusZones";

const Main: React.FC = () => {
  const { mapContext, groupContext, setMapContext } = useMapContext();
  const [realTimeStats, setRealTimeStats] = useReducer(statReducer, {
    oneHourTrips: 0,
    twentyFourHourTrips: 0,
  });
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;

  if (!keycloak || !keycloak.token) {
    throw new Error("Keycloak object is not available");
  }

  const [refs, setRefs] = useState<MapRefs>();
  const { mapNodeRef } = useMapInit({
    refs,
    setRefs,
    setMapContext,
    token: keycloak.token,
  });

  function statReducer(realTimeStats: any, action: any) {
    console.log(action);
    return action;
  }

  return (
    <Router>
      <MapProvider value={mapContext}>
        <GroupProvider value={groupContext}>
          <RealTimeStatsContext.Provider value={realTimeStats}>
            <RealTimeStatsDispatchContext.Provider value={setRealTimeStats}>
              <Container>
                <div
                  style={{
                    width: `calc(100% - 500px)`,
                    height: `calc(100% - 64px)`,
                    position: "absolute",
                    top: 64,
                    left: 0,
                  }}
                  className={"map-container"}
                  ref={mapNodeRef}
                ></div>
                <AutocompleteSearch />
                <Markers />
                <MapRoutes />
                <ContextMenu />
                <Campgrounds />
                <Incidents />
                <Waypoints />
                <AvoidAreas />
                <BusZones />
              </Container>
              <RealTimeButton />
              <Sidebar />
            </RealTimeStatsDispatchContext.Provider>
          </RealTimeStatsContext.Provider>
        </GroupProvider>
      </MapProvider>
    </Router>
  );
};

export default Main;
