import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import routingReducer from "./routing";
import uiReducer from "./ui";
import mapGridReducer from "./mapGrid";
import busZoneReducer from "./busZones";
import realTimeReducer from "./realTime";
import supportReducer from "./support";
import formReducer from "./routeForm";

const store = configureStore({
  reducer: {
    routing: routingReducer,
    ui: uiReducer,
    mapGrid: mapGridReducer,
    realTime: realTimeReducer,
    busZone: busZoneReducer,
    support: supportReducer,
    routeForm: formReducer,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
