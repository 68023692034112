import React, { useCallback, useEffect, useMemo, useState } from "react";
import SonarAPI from "./SonarAPI";
import BlipsTable from "./BlipsTable";
import MapInterface from "./MapInterface";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CreateBlipModal from "./SonarModals/CreateBlipModal";
import CreatedBlipModal from "./SonarModals/CreatedBlipModal";
import { Container } from "@mui/system";
import { AuthContext } from "stores/AuthContext";
import { MapObjectEvent } from "map/types";

import UpdateLocation from "./UpdateLocation";
import UpdateBlipModal from "./SonarModals/UpdateBlipModal";
import { CreateBlip, HazardType, hazardTypeLabels } from "./types/CreateBlip";
import { Blip, filterBlipsByHazardType } from "./types/Blip";
import { useHereMap } from "map/Context";
import { Select, MenuItem } from "@mui/material";
import FiltersModal from "./SonarModals/FiltersModal";
import JSONPretty from "react-json-pretty";
import { Filters } from "./types/Filters";
import { useAppSelector } from "stores/hooks";
import { selectRoute } from "stores/routing";
import { RouteKey } from "stores/types";
import { useLocation } from "react-router-dom";
import CheckBoxMapMount from "support/components/CheckBoxList";

const SonarSupport: React.FC = () => {
  const { map } = useHereMap();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [execute, setExecute] = useState(queryParams.get("execute") ?? "false");

  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }

  const { keycloak } = authContext;
  const sonarApi = useMemo(
    () => new SonarAPI(keycloak?.token ?? null),
    [keycloak?.token]
  );

  const [blips, setBlips] = useState<Blip[]>([]);

  const [selectedHazards, setSelectedHazards] = useState<HazardType[]>(
    Object.values(HazardType)
  );

  // Memoize the generation of checkbox items
  const checkBoxItems = useMemo(
    () =>
      Object.values(HazardType).map((hazard, index) => ({
        key: index,
        label: hazardTypeLabels[hazard],
        check: selectedHazards.includes(hazard),
        onCheck: () => {
          setSelectedHazards((prevSelectedHazards) =>
            prevSelectedHazards.includes(hazard)
              ? prevSelectedHazards.filter((h) => h !== hazard)
              : [...prevSelectedHazards, hazard]
          );
        },
      })),
    [selectedHazards]
  );

  const filteredBlips = useMemo(
    () => filterBlipsByHazardType(blips, selectedHazards),
    [blips, selectedHazards]
  );

  const [, setBoundingBox] = useState<{
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
  }>({
    xmax: 52,
    xmin: 22.273246422050725,
    ymax: -68.47481737499997,
    ymin: -122.43966112499997,
  });
  // values for creating a new blip
  const [mapTapped, setMapTapped] = useState<[number, number] | null>(null);
  const [createBlipRequest, setCreateBlipRequest] = useState("");
  const [renderPolylines, setRenderPolylines] = useState<string[]>([]);

  const [adjustView, setAdjustView] = useState(true);

  const [draggedBlip, setDraggedBlip] = useState<Blip | undefined>(undefined);
  const [oldBlip, setOldBlip] = useState<Blip | undefined>(undefined);

  // values for interacting with markers
  const [selectedMarker, setSelectedMarker] = useState<Blip | undefined>(
    undefined
  );

  const [showUpdate, setShowUpdate] = useState(false);
  const [resetDragged, setResetDragged] = useState(false);

  const [addFiltersOpen, setAddFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<Filters | undefined>(undefined);
  const [selectedQuery, setSelectedQuery] = useState<string>(
    queryParams.get("routeLine") != null
      ? "getAlongGeneratedRoute"
      : "getBlipsInMapBox"
  );
  const [bufferSize, setBuffer] = useState<number>(0.001);
  const [selectedRouteType, setSelectedRouteType] = useState<RouteKey>(
    (queryParams.get("routeLine") as RouteKey) ?? "car"
  );
  const [groupFactor, setGroupFactor] = useState<number>(400);
  const [showGroupFactorField, setShowGroupFactorField] = useState(false);

  const route = useAppSelector(selectRoute(selectedRouteType));
  // on filters change, refetch the blips

  const updateDraggedBlipState = async (
    lat: number,
    lon: number,
    dragID: String
  ) => {
    if (selectedMarker) {
      return;
    }
    setResetDragged(false);
    setDraggedBlip(undefined);
    let newBlip = blips.filter((blip) => blip.id === dragID)[0];
    setOldBlip(blips.filter((blip) => blip.id === dragID)[0]);
    let blip = { ...newBlip, lat: lat, lon: lon };
    setDraggedBlip(blip);
  };

  const resetObjects = useCallback(() => {
    setBlips([]);
    setRenderPolylines([""]);
  }, []);

  const fetchPolyLineBlips = useCallback(
    async (searchQuery: string[], bufferSize: number) => {
      resetObjects();
      const blipsData = await sonarApi.getBlipsAlongPolylines(
        searchQuery,
        bufferSize,
        filters,
        showGroupFactorField ? groupFactor : undefined
      );
      setBlips(blipsData);
      setRenderPolylines(searchQuery);
    },
    [resetObjects, sonarApi, filters, showGroupFactorField, groupFactor]
  );

  const fetchAllBlips = useCallback(async () => {
    resetObjects();
    try {
      setAdjustView(true);
      const blipsData = await sonarApi.getAllBlips();
      setBlips(blipsData);
      console.log("Blips:", blipsData);
    } catch (error) {
      console.error("Error fetching blips:", error);
    }
  }, [resetObjects, sonarApi]);
  const handleBoundingBoxChange = async (boundingBox: {
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
  }) => {
    setBoundingBox(boundingBox);
  };

  const getBlipsInMapBox = useCallback(async () => {
    try {
      console.log("Getting blips in map box");
      setAdjustView(false);
      let bounds = map?.getViewModel().getLookAtData().bounds;
      let box = bounds?.getBoundingBox();

      let left = box?.getLeft();
      let right = box?.getRight();
      let top = box?.getTop();
      let bottom = box?.getBottom();
      if (!left || !right || !top || !bottom) {
        return;
      }
      const blipsData = await sonarApi.getBlipsWithinBox(
        {
          xmax: top,
          xmin: bottom,
          ymax: right,
          ymin: left,
        },
        filters,
        showGroupFactorField ? groupFactor : undefined
      );
      setBlips(blipsData);
    } catch (error) {
      console.error("Error fetching blips:", error);
    }
  }, [map, sonarApi, filters, showGroupFactorField, groupFactor]);

  const fetchBlipsAlongGeneratedRoute = useCallback(async () => {
    // get the route from the map
    const routeData = route;
    if (!routeData) {
      console.error("No route data found");
      alert(
        "No route data found, enter the route first in the route tools tab"
      );
      return;
    }
    //get the polyline
    let polylines = [];
    polylines =
      route.routes[0].sections.map((section: any) => section.polyline) ?? [];
    console.log("Polylines:", polylines);

    polylines = polylines.filter((polyline) => polyline !== undefined);

    let blipsData = await sonarApi.getBlipsAlongPolylines(
      polylines,
      bufferSize,
      filters,
      showGroupFactorField ? groupFactor : undefined
    );

    setBlips(blipsData);
    setRenderPolylines(polylines);
  }, [route, sonarApi, bufferSize, filters, showGroupFactorField, groupFactor]);

  const fetchBlips = useCallback(async () => {
    console.log("Selected Query:", selectedQuery);
    switch (selectedQuery) {
      case "mapBoxBlips":
        getBlipsInMapBox();
        break;
      case "polyline":
        fetchPolyLineBlips(renderPolylines, bufferSize);
        break;
      case "allBlips":
        fetchAllBlips();
        break;
      case "getAlongGeneratedRoute":
        fetchBlipsAlongGeneratedRoute();
        break;
    }
  }, [
    bufferSize,
    fetchAllBlips,
    fetchBlipsAlongGeneratedRoute,
    fetchPolyLineBlips,
    getBlipsInMapBox,
    renderPolylines,
    selectedQuery,
  ]);

  const recallGetBlipsInMapBox = async (newFilters: Filters) => {
    let bounds = map?.getViewModel().getLookAtData().bounds;
    let box = bounds?.getBoundingBox();

    let left = box?.getLeft();
    let right = box?.getRight();
    let top = box?.getTop();
    let bottom = box?.getBottom();
    if (!left || !right || !top || !bottom) {
      return;
    }
    const blipsData = await sonarApi.getBlipsWithinBox(
      {
        xmax: top,
        xmin: bottom,
        ymax: right,
        ymin: left,
      },
      newFilters,
      showGroupFactorField ? groupFactor : undefined
    );
    setBlips(blipsData);
  };

  const clearBlips = () => {
    setBlips([]);
  };

  const onMapTapped = (lat: number, lon: number) => {
    if (selectedMarker) {
      return;
    }
    setMapTapped([lat, lon]);
  };

  const onMarkerTapped = (event: MapObjectEvent) => {
    setSelectedMarker(event.target?.getData());
    event.stopPropagation();

    setMapTapped(null);
  };

  const deleteMarker = async () => {
    if (!selectedMarker) {
      return;
    }
    try {
      // remove the marker
      const newBlips = blips.filter((blip) => blip.id !== selectedMarker.id);
      setBlips(newBlips);

      setSelectedMarker(undefined);
    } catch (error) {
      console.error("Error deleting blip:", error);
    }
  };

  const createNewBlip = async (createBlip: CreateBlip) => {
    setMapTapped(null);

    try {
      const response = await sonarApi.createBlip(createBlip);
      setCreateBlipRequest(JSON.stringify(response.data));
      setCreateBlipRequest(JSON.stringify(response.data));
    } catch (error) {
      console.error("Error creating blip:", error);
    }
  };
  const resetDraggedBlip = () => {
    setDraggedBlip(undefined);
    setOldBlip(undefined);
    setShowUpdate(false);
    setResetDragged(true);
    setTimeout(() => {
      setResetDragged(false);
    }, 100);
  };

  const updateBlip = async (blip: Blip) => {
    try {
      const response = await sonarApi.updateBlip(blip);
      console.log("Response:", response);
      if (response.status === 200) {
        //remove the old blip
        setBlips(blips.filter((b) => b.id !== blip.id));
        //go get the new data
        const blipsData = await sonarApi.getById(blip.id);
        console.log("Blips:", blipsData);

        setBlips(blips.map((b) => (b.id === blip.id ? blipsData : b)));
        setDraggedBlip(undefined);
      }
      setSelectedMarker(undefined);
    } catch (error) {
      console.error("Error updating blip:", error);
    }
  };

  const addFilters = () => {
    console.log("Add Filters");
    setAddFiltersOpen(true);
  };

  const closeFilters = () => {
    setAddFiltersOpen(false);
  };

  const handleDropdownSelect = (event: SelectChangeEvent) => {
    console.log("Event:", event.target.value);
    setSelectedQuery(event.target.value);
  };

  useEffect(() => {
    if (execute === "true") {
      fetchBlips();
      setExecute("false");
    }
  }, [execute, fetchBlips]);

  // create helpers for fetchPolyLineBlips

  return (
    <Container>
      <CheckBoxMapMount checkBoxItems={checkBoxItems} />{" "}
      {/* Add this component */}
      <UpdateBlipModal
        open={selectedMarker !== undefined}
        onClose={() => setSelectedMarker(undefined)}
        blip={selectedMarker}
        onUpdate={updateBlip}
        onDelete={deleteMarker}
      />
      <FiltersModal
        filters={filters}
        isOpen={addFiltersOpen}
        onClose={closeFilters}
        setFilters={setFilters}
        filterUpdate={recallGetBlipsInMapBox}
      />
      <CreateBlipModal
        open={
          mapTapped !== null &&
          mapTapped[0] !== 0 &&
          mapTapped[1] !== 0 &&
          !selectedMarker
        }
        onClose={() => setMapTapped([0, 0])}
        onCreate={createNewBlip}
        jsonData={
          {
            lat: mapTapped ? mapTapped[0] : 0,
            lon: mapTapped ? mapTapped[1] : 0,
          } || {}
        }
      />
      <CreatedBlipModal
        open={createBlipRequest !== ""}
        onClose={() => setCreateBlipRequest("")}
        response={createBlipRequest}
      />
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {draggedBlip ? (
              <Grid item xs={6} md={12}>
                <Typography variant="h5">You have dragged a blip!</Typography>
                <Typography variant="h5">
                  {"Would you like to update it?:"}
                </Typography>
                <Typography variant="h6">{draggedBlip.id}</Typography>
                <ButtonGroup variant="contained" aria-label="button group">
                  <Button onClick={() => setShowUpdate(true)}>
                    Show Update
                  </Button>
                  <Button onClick={resetDraggedBlip}>Cancel Update</Button>
                </ButtonGroup>
              </Grid>
            ) : (
              <Grid item xs={6} md={12}>
                <Typography variant="h5" gutterBottom>
                  Get Blips:
                </Typography>
                <Grid item xs={6} md={12}>
                  <Select
                    value={selectedQuery}
                    onChange={handleDropdownSelect}
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select an option
                    </MenuItem>
                    <MenuItem value="mapBoxBlips">Get blips in mapbox</MenuItem>
                    <MenuItem value="polyline">Get Along Polyline</MenuItem>
                    <MenuItem value="allBlips">Get all Blips</MenuItem>
                    <MenuItem value="getAlongGeneratedRoute">
                      Get Along Generated Route
                    </MenuItem>
                  </Select>
                  <Grid item xs={6} md={12}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showGroupFactorField}
                            onChange={(e) =>
                              setShowGroupFactorField(e.target.checked)
                            }
                            name="showTextField"
                          />
                        }
                        label="Apply Group by Distance Factor"
                      />
                    </Grid>
                    {showGroupFactorField && (
                      <Grid item xs={12}>
                        <TextField
                          label="Group by a distance factor (in meters)"
                          fullWidth
                          type="number"
                          value={groupFactor}
                          onChange={(e) =>
                            setGroupFactor(parseInt(e.target.value))
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={6} md={12}>
                    {selectedQuery === "getAlongGeneratedRoute" && (
                      <Select
                        value={selectedRouteType}
                        onChange={(e) =>
                          e.target.value
                            ? setSelectedRouteType(e.target.value as RouteKey)
                            : ""
                        }
                        fullWidth
                      >
                        <MenuItem value="car">Car</MenuItem>
                        <MenuItem value="truck">Truck</MenuItem>
                        <MenuItem value="bus">Bus</MenuItem>
                      </Select>
                    )}
                  </Grid>
                  {selectedQuery === "polyline" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Enter Polyline"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            e.target.value
                              ? setRenderPolylines([e.target.value])
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(selectedQuery === "polyline" ||
                    selectedQuery === "getAlongGeneratedRoute") && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Enter Buffer Size"
                          variant="outlined"
                          type="number"
                          fullWidth
                          value={bufferSize}
                          onChange={(e) => {
                            if (e.target.value) {
                              console.log("Buffer Size:", e.target.value);
                              setBuffer(parseFloat(e.target.value));
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  alignContent="center"
                  justifyContent="center"
                >
                  {filters !== undefined && (
                    <>
                      <Typography variant="h5" gutterBottom>
                        Filters:
                      </Typography>
                      <Typography variant="h6">
                        <JSONPretty data={filters}></JSONPretty>
                      </Typography>
                    </>
                  )}
                  <ButtonGroup variant="contained" aria-label="button group">
                    <Button onClick={addFilters}>Add Filters</Button>
                    <Button onClick={fetchBlips}>Fetch Blips</Button>
                    <Button onClick={clearBlips}>Clear Blips</Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}
          </Grid>
          {draggedBlip && (
              <Button onClick={() => setShowUpdate(true)}>Show Update</Button>
            ) && ( // Add this button
              <UpdateLocation
                oldBlip={oldBlip}
                newBlip={{ ...draggedBlip }}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
                onUpdate={updateBlip}
              />
            )}
          {!draggedBlip && <BlipsTable blips={blips} />}
        </Grid>
      </Container>
      <MapInterface
        blips={filteredBlips} // Use filtered blips
        resetDragged={resetDragged}
        onBoundingBoxChange={handleBoundingBoxChange}
        onMapTapped={onMapTapped}
        polylines={renderPolylines}
        onMarkerTapped={onMarkerTapped}
        adjustView={adjustView}
        updateBlipPosition={(lat, lon, dragID) =>
          updateDraggedBlipState(lat, lon, dragID)
        }
        draggedBlip={draggedBlip}
      />
    </Container>
  );
};

export default SonarSupport;
