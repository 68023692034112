import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid";

import {
  awsResponseToRouteItemList,
  queryUserRouteHash,
  queryUserRoutes,
  queryUserRoutesWithCommentsAndRating,
  updateRouteFixedStatus,
} from "util/awsfunctions";
import { convertFeetToFeetAndInches, getBooleanValue } from "util/conversion";
import RouteDetails from "./RouteDetails";
import { Route } from "./models/Route";
import { AwsRecord } from "./models/Types";
import { SearchButtons } from "./components/SearchButtons";
import { SearchField } from "./components/SearchField";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { setPMUserId, setRouteHash } from "stores/support";

const RouteSupport: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const { pmUserId: pmUserIdParam, routeHash: routeHashParam } = useParams();
  const routeItems = useRef<Route[]>([]);
  const [query, setQuery] = useState("");
  const [routeHashQuery, setRouteHashQuery] = useState("");
  const [rows, setRows] = useState<Route[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [routeHashsearchValue, setRouteHashsetSearchValue] =
    useState<string>("");
  const [activeRoute, setActiveRoute] = useState<Route | null>(null);
  const dataGridRef = useGridApiRef();
  const dispatch = useAppDispatch();
  const routeHash = useAppSelector((state) => state.support.routeHash);
  const pmUserId = useAppSelector((state) => state.support.pmUserId);

  const getColumns = (
    setActiveRoute: React.Dispatch<React.SetStateAction<Route | null>>
  ): GridColDef[] => [
    {
      field: "id",
      headerName: "Route Hash ID",
      width: 300,
      headerAlign: "center",
      renderCell: (params) => (
        <Button onClick={() => setActiveRoute(params.row)}>
          <Typography color="blue" variant="body2">
            {params.value}
          </Typography>
        </Button>
      ),
    },
    {
      field: "isRouteFixed",
      headerName: "Fixed",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) =>
            handleRouteFixedChange(
              params.row.pmUserId,
              params.row.id,
              event.target.checked
            )
          }
        />
      ),
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 75,
    },
    {
      field: "destinationName",
      headerName: "Destination",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            whiteSpace: "normal",
            wordWrap: "break-word",
            lineHeight: "16px",
            minHeight: 52,
            paddingBlock: 4,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 500,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            whiteSpace: "normal",
            wordWrap: "break-word",
            lineHeight: "16px",
            minHeight: 52,
            paddingBlock: 4,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "commentCreatedAt",
      headerName: "Comment Created At",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const date = new Date(params.value) || "";
        return date.toLocaleDateString();
      },
    },
    {
      field: "name",
      headerName: "Date",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const date = new Date(params.value) || "";
        return date.toLocaleDateString();
      },
    },
    {
      field: "pmUserId",
      headerName: "PM User ID",
      width: 100,
    },
    {
      field: "rvType",
      headerName: "RV Type",
      width: 125,
    },
    {
      field: "rvtwTripId",
      headerName: "RVTW Trip ID",
      width: 100,
    },
    {
      field: "routingMode",
      headerName: "Routing Mode",
      width: 100,
    },
    {
      field: "routingType",
      headerName: "Route Type",
      width: 125,
    },
    {
      field: "vehicleLength",
      headerName: "Length",
      width: 100,
      valueFormatter: (params) => convertFeetToFeetAndInches(params.value),
    },
    {
      field: "vehicleHeight",
      headerName: "Height",
      width: 100,
      valueFormatter: (params) => convertFeetToFeetAndInches(params.value),
    },
    {
      field: "vehicleWeight",
      headerName: "Weight",
      width: 125,
      valueFormatter: (params) => `${params.value} lbs`, // Assuming weight is in pounds
    },
    // Converting boolean fields to 'Yes' or 'No'
    {
      field: "avoidFerries",
      headerName: "Avoid Ferries",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "avoidHighways",
      headerName: "Avoid Highways",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "avoidTolls",
      headerName: "Avoid Tolls",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "avoidUnpaved",
      headerName: "Avoid Unpaved",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "avoidTunnels",
      headerName: "Avoid Tunnels",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "avoidTraffic",
      headerName: "Avoid Traffic",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "hasPropane",
      headerName: "Has Propane",
      width: 100,
      valueFormatter: ({ value }) => getBooleanValue(value),
    },
    {
      field: "appVersion",
      headerName: "Version",
      width: 100,
    },
    {
      field: "processingVersion",
      headerName: "Processing Ver.",
      width: 125,
    },
  ];
  const columns: GridColDef[] = getColumns(setActiveRoute);

  useEffect(() => {
    if (pmUserIdParam) {
      dispatch(setPMUserId(pmUserIdParam));
    } else if (routeHashParam) {
      dispatch(setRouteHash(routeHashParam));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmUserIdParam, routeHashParam, dispatch]);

  useEffect(() => {
    if (pmUserId) {
      queryRoutes(pmUserId);
    } else if (routeHash) {
      queryRouteHash(routeHash);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmUserId, routeHash]);

  const setRouteHashSearchValue = useCallback((searchValue: string) => {
    setRouteHashsetSearchValue(searchValue);
    setSearchValue("");
    setQuery("");
  }, []);

  const setPMUserIDSearchValue = useCallback((searchValue: string) => {
    setSearchValue(searchValue);
    setRouteHashsetSearchValue("");
    setRouteHashQuery("");
  }, []);

  const queryRouteHash = useCallback(
    (hash: string) => {
      if (!open) setOpen(true);
      if (!hash) {
        return;
      }
      queryUserRouteHash(hash).then(async (data) => {
        if (data) {
          routeItems.current = awsResponseToRouteItemList(data);
          setRows(routeItems.current);
        }
      });
    },
    [open, setOpen]
  );

  const queryRoutes = useCallback(
    (id?: string) => {
      if (!open) setOpen(true);
      if (!id) {
        return;
      }

      queryUserRoutes(id).then(async (data) => {
        if (data) {
          routeItems.current = awsResponseToRouteItemList(data);
          setRows(routeItems.current);
        }
      });
    },
    [open, setOpen]
  );

  const queryHandler = useCallback(
    (id: string, routeHash: string) => {
      if (!open) setOpen(true);

      if (id) {
        queryRoutes(id);
      } else if (routeHash) {
        queryRouteHash(routeHash);
      }
    },
    [open, queryRouteHash, queryRoutes, setOpen]
  );

  const getCommentedEntries = useCallback(() => {
    const combineRatings = async () => {
      const ratings = [1, 2, 3];
      const combinedResults: AwsRecord[] = await Promise.all(
        ratings.flatMap(async (rating) => {
          const results = await queryUserRoutesWithCommentsAndRating(rating);
          return results || [];
        })
      ).then((results) => results.flat());

      return combinedResults;
    };

    combineRatings().then(async (data) => {
      if (data) {
        routeItems.current = awsResponseToRouteItemList(data);
        setRows(routeItems.current);
      }
    });
  }, []);
  const clearEntries = () => {
    setRows([]);
    setQuery("");
    setRouteHashQuery("");
  };

  const handleRouteFixedChange = useCallback(
    async (pmUserId: string, routeHash: string, isFixed: boolean) => {
      await updateRouteFixedStatus(pmUserId, routeHash, isFixed);
      setRows((previousRows) =>
        previousRows.map((row) =>
          row.id === routeHash
            ? { ...row, isRouteFixed: isFixed ? "YES" : "" }
            : row
        )
      );
    },
    []
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 0 }}>
      <Stack direction="row" sx={{ my: 1 }}>
        <SearchField
          searchValue={searchValue}
          setSearchValue={setPMUserIDSearchValue}
          setQuery={setQuery}
          onSearch={() => queryRoutes(query)}
          onClear={clearEntries}
          placeholder="PM User ID"
        />
        <SearchField
          searchValue={routeHashsearchValue}
          setSearchValue={setRouteHashSearchValue}
          setQuery={setRouteHashQuery}
          onSearch={() => queryRouteHash(routeHashQuery)}
          onClear={clearEntries}
          placeholder="Route Hash ID"
        />
        <SearchButtons
          onSearch={() => queryHandler(query, routeHashQuery)}
          onExport={() => dataGridRef.current?.exportDataAsCsv()}
          onGetCommentedEntries={getCommentedEntries}
          open={open}
        />
      </Stack>
      <div style={{ height: "calc(100vh - 121px)", width: "100%" }}>
        <DataGrid
          apiRef={dataGridRef}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
            sorting: { sortModel: [{ field: "name", sort: "desc" }] },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          disableRowSelectionOnClick
          getRowHeight={() => "auto"}
        />
        {activeRoute && (
          <RouteDetails
            route={activeRoute}
            handleClose={() => setActiveRoute(null)}
          />
        )}
      </div>
    </Box>
  );
};

export default RouteSupport;
