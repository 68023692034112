import { LatLngLiteral } from "map/types";
import {
  feetToCentimeters,
  feetToMeters,
  poundsToKg,
  poundsToMetricTons,
} from "util/conversion";
import axios from "axios";
import { Position, decode as decodePolyline } from "util/flexible-polyline";
import { getURL } from "util/url";

export interface RouteParams {
  dirtRoad: boolean;
  tollRoad: boolean;
  highway: boolean;
  tunnel: boolean;
  ferry: boolean;
  propane: boolean;
  height: string;
  length: string;
  width: string;
  weight: string;
  car: boolean;
  truck: boolean;
  bus: boolean;
  compareParams: boolean;
  approachWaypoints: boolean;
  departureWaypoints: boolean;
}

export const getRoute = async (
  postObject: any,
  dev: boolean,
  token: string,
  logout: () => void
) => {
  try {
    const response = await axios.post(
      `${getURL()}/v2/atlas-routing?source=atlas`,
      postObject,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      logout();
      console.error("Unauthorized: Please check your credentials.");
      // You can add additional handling here, such as logging out the user or refreshing the token
    } else {
      // Handle other errors
      console.error("An error occurred:", error);
    }
    throw error; // Re-throw the error after handling it
  }
};

async function getEncodedPolylineFromRoute(
  origin: [number, number],
  destination: [number, number],
  MapboxToken: string,
  height: string = "1",
  weight: string = "1"
): Promise<string> {
  const originRounded = [
    parseFloat(origin[0].toFixed(4)),
    parseFloat(origin[1].toFixed(4)),
  ];
  const destinationRounded = [
    parseFloat(destination[0].toFixed(4)),
    parseFloat(destination[1].toFixed(4)),
  ];

  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${originRounded[1]},${originRounded[0]};${destinationRounded[1]},${destinationRounded[0]}.json?access_token=${MapboxToken}&geometries=geojson&max_height=${height}&max_weight=${weight}`;

  const response = await axios.get(url);

  if (response.status !== 200 || response.data.routes.length === 0) {
    return "Error getting route";
  }
  return response.data.routes[0].geometry;
}

const MapboxToken =
  "pk.eyJ1Ijoic29jaWFsa25vd2xlZGdlIiwiYSI6ImNsa2ExM2I1cDAyZ3Yzc3F2Nnhka2s4YnAifQ.NOBu0GlJFPrW0cv0NQYVcw"; // replace with your Mapbox Access token

// add a polyline onto the HERE map using the HERE SDK with a mapRef and an encoded polyline

export const route = (
  onSuccess: (a: any, b?: boolean) => any,
  onNPSuccess: (a: any) => any,
  onError: (a: Error) => any,
  token: string,
  params: RouteParams,
  logout: () => void,
  mapRef?: H.Map,
  origin?: LatLngLiteral,
  destination?: LatLngLiteral,
  avoidAreas?: string,
  approach?: WaypointApproach,
  departure?: WaypointApproach
) => {
  if (mapRef && origin && destination) {
    const {
      compareParams,
      dirtRoad,
      ferry,
      highway,
      tunnel,
      tollRoad,
      propane,
      weight,
      length,
      height,
      width,
      approachWaypoints,
      departureWaypoints,
    } = params;

    const routeObject = {
      avoidances: {
        toll: false,
        dirtRoad: false,
        ferry: false,
        highway: false,
        tunnel: false,
        propane: false,
      },
      rv_info: {
        height: 0,
        length: 0,
        width: 0,
        weight: 0,
      },
      routes: [
        {
          origin: [origin.lat, origin.lng],
          waypoints: [] as Position[],
          destination: [destination.lat, destination.lng],
        },
      ],
      metric: true,
      routing_type: "rv",
      user_id: "0000000",
      avoidAreas,
    };

    if (dirtRoad) {
      routeObject.avoidances.dirtRoad = true;
    }
    if (ferry) {
      routeObject.avoidances.ferry = true;
    }
    if (highway) {
      routeObject.avoidances.highway = true;
    }
    if (tunnel) {
      routeObject.avoidances.tunnel = true;
    }
    if (tollRoad) {
      routeObject.avoidances.toll = true;
    }
    if (propane) {
      routeObject.avoidances.propane = true;
    }

    if (weight) {
      routeObject.rv_info.weight = parseInt(poundsToKg(weight));
    }
    if (height) {
      routeObject.rv_info.height = parseInt(feetToCentimeters(height));
    }
    if (length) {
      routeObject.rv_info.length = parseInt(feetToCentimeters(length));
    }
    if (width) {
      routeObject.rv_info.width = parseInt(feetToCentimeters(width));
    }

    if (approachWaypoints && approach) {
      const decodedPolyline = decodePolyline(approach.polyline);
      routeObject.routes[0].waypoints = routeObject.routes[0].waypoints.concat(
        decodedPolyline.polyline
      );
    }

    if (departureWaypoints && departure) {
      const decodedPolyline = decodePolyline(departure.polyline);
      routeObject.routes[0].waypoints = [
        ...decodedPolyline.polyline,
        ...routeObject.routes[0].waypoints,
      ];
    }

    getEncodedPolylineFromRoute(
      [origin.lat, origin.lng],
      [destination.lat, destination.lng],
      MapboxToken,
      feetToMeters(height || "6", 2),
      poundsToMetricTons(weight || "500", 2)
    )
      .then((response) => {
        onSuccess(response, true);
      })
      .catch((error) => console.error(error));

    getRoute({ ...routeObject, routing_type: "car" }, false, token, logout)
      .then((response) => {
        onSuccess(response.data.journeys[0]);
      })
      .catch((error) => onError(error));
    getRoute({ ...routeObject, routing_type: "rv" }, false, token, logout)
      .then((response) => {
        onSuccess(response.data.journeys[0]);
      })
      .catch((error) => onError(error));
    getRoute({ ...routeObject, routing_type: "bus" }, false, token, logout)
      .then((response) => {
        onSuccess(response.data.journeys[0]);
      })
      .catch((error) => onError(error));

    if (compareParams) {
      routeObject.avoidances = {
        toll: false,
        dirtRoad: false,
        ferry: false,
        highway: false,
        tunnel: false,
        propane: false,
      };
      getRoute({ ...routeObject, routing_type: "car" }, false, token, logout)
        .then((response) => {
          onNPSuccess(response.data.journeys[0]);
        })
        .catch((error) => onError(error));
      getRoute({ ...routeObject, routing_type: "rv" }, false, token, logout)
        .then((response) => {
          onNPSuccess(response.data.journeys[0]);
        })
        .catch((error) => onError(error));
      getRoute({ ...routeObject, routing_type: "bus" }, false, token, logout)
        .then((response) => {
          onNPSuccess(response.data.journeys[0]);
        })
        .catch((error) => onError(error));
    }
  }
};
