import { LegendEntry } from "../components/Legend";

export const legendItems: LegendEntry[] = [
  {
    key: 1,
    color: "#C51616",
    title: "Route Recalculation",
  },
  {
    key: 2,
    color: "#000000",
    title: "User Cancelled",
  },
  {
    key: 3,
    color: "#AF18B2",
    title: "User Defined WayPoints",
  },
];
