import React from "react";
import H from "@here/maps-api-for-javascript";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM, HERE_API_KEY } from "../const";
import { MapInfo } from "map/Context";
import { MapRefs } from "map/types";
import { getLayerGroup } from "map/util/addLayerGroup";

export const ROUTE_OVERLAY_LAYER_NAME = "routeOverlay";
export const MARKER_LAYER_NAME = "markers";
interface UseMapInitProps {
  refs?: MapRefs;
  setRefs: React.Dispatch<React.SetStateAction<MapRefs | undefined>>;
  setMapContext: React.Dispatch<React.SetStateAction<MapInfo>>;
  token: string;
}
/**
 * Hook that handles initializing the map, and catching changes to map layers and rendering engine.
 */
const useMapInit = ({
  refs,
  setRefs,
  setMapContext,
  token,
}: UseMapInitProps) => {
  const mapNodeRef = React.useRef<HTMLDivElement>(null);

  const [mapRef, setMapRef] = React.useState<H.Map>();

  const [uiRef, setUiRef] = React.useState<H.ui.UI>();

  React.useEffect(() => {
    const platform = new H.service.Platform({
      apikey: HERE_API_KEY,
    });
    const omvService = platform.getOMVService();
    const layers = omvService.createLayer(
      new H.map.Style(
        "https://js.api.here.com/v3/3.1/styles/omv/miami/normal.day.yaml"
      )
    );
    const map = new H.Map(mapNodeRef.current as HTMLDivElement, layers, {
      center: DEFAULT_MAP_CENTER,
      zoom: DEFAULT_MAP_ZOOM,
      engineType: H.Map.EngineType.WEBGL,
    });
    const ui = new H.ui.UI(map);
    const zoomControl = new H.ui.ZoomControl({
      fractionalZoom: false,
      alignment: "right-top",
    });
    const controlLayers = [{ label: "Normal", layer: layers }];
    ui.addControl("zoom", zoomControl);

    const makeWeatherLayer = (weather: string, label: string) => {
      const source = process.env.NODE_ENV === "development" ? "dev" : "prod";

      const url = `https://d1ef41l1vstpnl.cloudfront.net/${weather}/{z}/{x}/{y}/current.png?token=${token}&source=${source}`;
      const attribution =
        "<a href=‘https://www.aerisweather.com/’>&copy;AerisWeather</a>";
      const provider = new H.map.provider.ImageTileProvider({
        getURL: (col, row, zoom) => {
          return url
            .replace("{z}", `${zoom}`)
            .replace("{x}", `${col}`)
            .replace("{y}", `${row}`);
        },
        getCopyrights: () => {
          return [{ label: attribution }];
        },
      });
      const layer = new H.map.layer.TileLayer(provider);

      return { label, layer };
    };

    const weatherLayers = [
      { weather: "radar:70", label: "Radar" },
      { weather: "fwind-speeds:60,wind-dir", label: "Windspeed" },
    ].map(({ weather, label }) => makeWeatherLayer(weather, label));

    ui.addControl(
      "mapsettings",
      new H.ui.MapSettingsControl({
        baseLayers: controlLayers,
        layers: weatherLayers,
      })
    );
    const extraLayers =
      refs?.extraLayers ?? new Map<string, H.map.layer.ObjectLayer>();
    let routeOverlayLayer: H.map.layer.ObjectLayer;
    let markerLayer: H.map.layer.ObjectLayer;

    if (extraLayers === refs?.extraLayers) {
      routeOverlayLayer = extraLayers.get(
        ROUTE_OVERLAY_LAYER_NAME
      ) as H.map.layer.ObjectLayer;
      markerLayer = extraLayers.get(
        MARKER_LAYER_NAME
      ) as H.map.layer.ObjectLayer;
    } else {
      // Add a route overlay layer just over the default object layer
      const routeOverlayProvider = new H.map.provider.LocalObjectProvider();
      routeOverlayLayer = new H.map.layer.ObjectLayer(routeOverlayProvider);
      extraLayers.set(ROUTE_OVERLAY_LAYER_NAME, routeOverlayLayer);

      // Add a marker layer to ensure markers render on top of other shapes
      const markerLayerProvider = new H.map.provider.LocalObjectProvider();
      markerLayer = new H.map.layer.ObjectLayer(markerLayerProvider);
      extraLayers.set(MARKER_LAYER_NAME, markerLayer);
    }

    map.addLayer(routeOverlayLayer, 2);
    map.addLayer(markerLayer, 3);
    const mapEvents = new H.mapevents.MapEvents(map);
    const behavior = new H.mapevents.Behavior(mapEvents);
    setMapContext({
      map,
      ui,
      platform,
      behavior,
      addObject: (obj, lyr) => {
        if (lyr) {
          const objLayer = extraLayers.get(lyr);
          if (!objLayer) {
            throw new Error(`Unknown layer "${lyr}"`);
          }
          getLayerGroup(objLayer).addObject(obj);
        } else {
          map.addObject(obj);
        }
      },
      removeObject: (obj, lyr) => {
        if (lyr) {
          const objLayer = extraLayers.get(lyr);
          if (!objLayer) {
            throw new Error(`Unknown layer "${lyr}"`);
          }
          getLayerGroup(objLayer).removeObject(obj);
        } else {
          try {
            map.removeObject(obj);
          } catch (e) {
            // TODO removeObject is only failing in safari on switching map modes, requiring this change
          }
        }
      },
    });

    setRefs({
      map,
      ui,
      behavior,
      platform,
      defaultLayers: layers,
      extraLayers,
    });
    setMapRef(map);
    setUiRef(ui);
    return () => {
      setRefs(undefined);
      map.removeLayer(routeOverlayLayer);
      map.removeLayer(markerLayer);
      setMapContext({
        map: undefined,
        ui: undefined,
        platform: undefined,
        behavior: undefined,
        addObject: undefined,
        removeObject: undefined,
      });

      setTimeout(() => {
        map.dispose();
        //           resizeObserver.unobserve(mapNode);
        //           map.getViewModel().dispose();
        //           map.getViewPort().dispose();
        //           map.dispose();
        ui.dispose();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { mapRef, mapNodeRef, uiRef };
};

export default useMapInit;
