const POUNDS_PER_KG = 2.20462;

const FEET_PER_METER = 3.28084;

export function poundsToKg(pounds: string, resolution = 0): string {
  return (parseFloat(pounds) / POUNDS_PER_KG).toFixed(resolution);
}

export function feetToCentimeters(feet: string, resolution = 0): string {
  return ((parseFloat(feet) / FEET_PER_METER) * 100).toFixed(resolution);
}

export function feetToMeters(feet: string, resolution = 0): string {
  return (parseFloat(feet) / FEET_PER_METER).toFixed(resolution);
}

export function poundsToMetricTons(pounds: string, resolution = 0): string {
  return (parseFloat(pounds) / 2204.62).toFixed(resolution);
}
export function convertPoundsToKilograms(pounds: number) {
  return Math.round(pounds / 2.2046);
}

export function convertFeetToCentimeters(feet: number) {
  return Math.round(feet / 0.032808);
}

export const metricToImperial = (value: any, type: string) => {
  if (value === undefined || value === null) {
    return value;
  }
  let coercedValue = parseFloat(value);
  if (isNaN(coercedValue)) {
    return value;
  }
  if (type === "cm") {
    const inches = value * 0.393701; // convert centimeters to inches
    const feet = Math.floor(inches / 12);
    const remainingInches = (inches % 12).toFixed(0);
    return `${feet}' ${remainingInches}"`;
  } else if (type === "kg") {
    const pounds = (value * 2.20462).toFixed(0); // convert kilograms to pounds
    return `${pounds} lbs`;
  }
  return value;
};

export function getStringValue(value: string | undefined): string {
  if (value === undefined) {
    return "?";
  }

  return value;
}

export function getBooleanValue(
  value: boolean | undefined,
  truthValue: string = "Y",
  falseValue: string = "N"
): string {
  if (value === undefined) {
    return "?";
  }

  return value ? truthValue : falseValue;
}

export function getNumberValue(
  value: number | undefined,
  suffix?: string
): string {
  if (value === undefined) {
    return "?";
  }

  return suffix ? `${value} ${suffix}` : `${value}`;
}

// convert feet into feet and inches and return as string
export const convertFeetToFeetAndInches = (feet?: number) => {
  if (feet === undefined) {
    return undefined;
  }
  const inches = (feet - Math.floor(feet)) * 12;
  const feetAndInches = `${Math.floor(feet)}' ${Math.floor(inches)}"`;
  return feetAndInches;
};
