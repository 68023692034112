import "./App.css";
import Main from "./map/Main";
import CssBaseline from "@mui/material/CssBaseline";
import store from "./stores/store";
import { Provider } from "react-redux";
import AuthProvider from "stores/AuthProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <Provider store={store}>
          <CssBaseline />
          <Main />
        </Provider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
