import algoliasearch from "algoliasearch";
import { SearchIndex } from "algoliasearch";
import { DiscoverItem } from "tools/Autocomplete";

interface ApiOptions {
  algoliaIndex: SearchIndex;
}

const algoliaErrorTypes = {
  UnreachableHost: {
    error: /Unreachable hosts/i,
    fallbackMessage:
      "We encountered an error with your search. You might be too far from our servers or have a weak connection. If the problem continues, please contact support.",
  },
} as const;

export const highlightString = (
  haystack: string,
  needle: string,
  regexCache?: Record<string, RegExp>
): string => {
  // If needle is empty, do not process further
  if (!needle) {
    return haystack;
  }

  let needleRe = needle && regexCache && regexCache[needle];
  if (!needleRe) {
    const needleRegex = needle.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
    needleRe = new RegExp(needleRegex, "i");
    if (regexCache) {
      regexCache[needleRegex] = needleRe;
    }
  }
  return haystack.replace(needleRe, (match) => {
    return `<b>${match}</b>`;
  });
};

export class AlgoliaApi {
  algoliaIndex: SearchIndex;

  constructor(options: ApiOptions) {
    this.algoliaIndex = options.algoliaIndex;
  }

  async parkSuggestion(query: string): Promise<DiscoverItem[]> {
    try {
      const res = await this.algoliaIndex.search<AlgoliaAutocompleteQueryHit>(
        query
      );
      const parks = res.hits;
      const discoverItems: DiscoverItem[] = parks.map((park) => {
        return {
          title: park.cg_name,
          id: park.objectID, // Assuming objectID is a string; adjust if it's not
          language: "en", // Assuming english; adjust based on your needs
          resultType: "park",
          address: {
            label: `${park.city_name}, ${park.region_name}`, // Simple label; adjust based on actual address data if available
            city: park.city_name,
            region: park.region_name,
          },
          position: {
            lat: park._geoloc.lat,
            lng: park._geoloc.lng,
          },
          // access, categories, contacts, etc., fill these if the data is available or applicable
          distance: 0, // Set this based on user's location or other logic
        };
      });

      return discoverItems;
    } catch (err: any) {
      if (algoliaErrorTypes.UnreachableHost.error.test(err.message)) {
        console.log(algoliaErrorTypes.UnreachableHost.fallbackMessage);
        return [];
      }
      throw err;
    }
  }
}

export const algolia = new AlgoliaApi({
  algoliaIndex: algoliasearch(
    "H0LPZK92QJ",
    "8825ea01bb987b0f437b31be5a8f0298"
  ).initIndex("jafodevpark"),
});
