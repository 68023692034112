import H from "@here/maps-api-for-javascript";
import React, { useEffect, useState, useRef } from "react";
import { Route } from "./models/Route";
import CheckBoxMapMount, { CheckBoxEntry } from "./components/CheckBoxList";
import { pink } from "@mui/material/colors";
import { useHereMap } from "map/Context";
import LegendMapMount from "./components/Legend";
import { useRouteData } from "./hooks/useRouteData";
import {
  displayMinimalRoute,
  displayActualRoute,
  displayCalculatedRoute,
} from "./displayRouteLogic";
import { createGeoRect } from "util/mapFunctions";
import { AuthContext } from "stores/AuthContext";

const RouteDetails: React.FC<{
  route: Route;
  handleClose: () => void;
}> = ({ route }) => {
  const routeHash = route.id;
  const { fromLat, fromLng, destinationLat, destinationLng } = route;
  const [actualRouteVisible, setActualRouteVisible] = useState(true);
  const [calculatedRouteVisible, setCalculatedRouteVisible] = useState(false);
  const { map } = useHereMap();
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;
  if (!keycloak) {
    throw new Error("Keycloak is not available");
  }

  const actualRouteObjectsRef = useRef<H.map.Object[]>([]);
  const calculatedRouteObjectsRef = useRef<H.map.Object[]>([]);

  const removeMapObjects = React.useCallback(
    (objects: H.map.Object[]) => {
      objects.forEach((obj) => {
        try {
          map?.removeObject(obj);
        } catch (e) {
          console.log(obj);
        }
      });
      objects.length = 0; // Clear the array after removing the objects
    },
    [map]
  );

  const routeData = useRouteData(routeHash);

  useEffect(() => {
    // Only display the route if we have the required data
    if (routeData && routeData.length > 1) {
      if (actualRouteVisible) {
        const { mapObjects: actualRouteMapObjects, bounds } =
          displayActualRoute(routeData, route);

        actualRouteObjectsRef.current = actualRouteMapObjects;
        map?.addObjects(actualRouteObjectsRef.current);
        if (bounds) {
          map?.getViewPort().setPadding(200, 200, 200, 200);
          map?.getViewModel().setLookAtData({ bounds });
        }
      }
      if (calculatedRouteVisible) {
        const handleRenderComplete = (createdMapObjects: H.map.Object[]) => {
          // Here you would use the created map objects, for example, assigning them to a ref
          calculatedRouteObjectsRef.current = createdMapObjects;
          map?.addObjects(calculatedRouteObjectsRef.current);
          // Update view port based on route line
          map?.getViewPort().setPadding(200, 200, 200, 200);

          map?.getViewModel().setLookAtData({
            bounds: createGeoRect(
              routeData[0].lat,
              routeData[0].lng,
              destinationLat,
              destinationLng
            ),
          });
        };
        displayCalculatedRoute(
          routeData,
          route,
          handleRenderComplete,
          keycloak.token!
        );
      }
    } else {
      const markerGroup = displayMinimalRoute(
        fromLat,
        fromLng,
        destinationLat,
        destinationLng
      );
      actualRouteObjectsRef.current.push(markerGroup);
      map?.addObjects(actualRouteObjectsRef.current);
      map?.getViewPort().setPadding(200, 200, 200, 200);
      map
        ?.getViewModel()
        .setLookAtData({ bounds: markerGroup.getBoundingBox() });
    }
    return () => {
      removeMapObjects(actualRouteObjectsRef.current);
      removeMapObjects(calculatedRouteObjectsRef.current);
    };
  }, [
    routeData,
    actualRouteVisible,
    calculatedRouteVisible,
    destinationLat,
    destinationLng,
    fromLat,
    fromLng,
    map,
    removeMapObjects,
    route,
    keycloak.token,
  ]);

  // useEffect(() => {
  //   const fetchAndUpdateRouteOnMap = async () => {
  //     if (!routeHash) return;

  //     const data = await getRoutePoints(routeHash);
  //     if (!data || data.length <= 1) {
  //       const startMarker = createMarker(
  //         fromLat,
  //         fromLng,
  //         StartMarker,
  //         undefined,
  //         48,
  //         56
  //       );
  //       const endMarker = createMarker(
  //         destinationLat,
  //         destinationLng,
  //         FinishMarker,
  //         undefined,
  //         48,
  //         56
  //       );
  //       const markerGroup = new H.map.Group();
  //       markerGroup.addObjects([startMarker, endMarker]);
  //       actualRouteObjectsRef.current.push(markerGroup);
  //       map?.addObjects(actualRouteObjectsRef.current);
  //       map?.getViewPort().setPadding(200, 200, 200, 200);
  //       map
  //         ?.getViewModel()
  //         .setLookAtData({ bounds: markerGroup.getBoundingBox() });

  //       return;
  //     }

  //     const { polyLine, startMarker, endMarker, bounds } = displayOnMap(data);

  //     // Displaying the actual route on the map
  //     if (actualRouteVisible) {
  //       const mapObjects = [polyLine, startMarker, endMarker];

  //       // add route recalculations
  //       data.forEach((it: any) => {
  //         if (it.routeStopReason === RouteRecalculationReason) {
  //           const marker = createMarker(
  //             it.lat,
  //             it.lng,
  //             LocationMarker,
  //             undefined
  //           );
  //           mapObjects.push(marker);
  //         } else if (it.routeStopReason === RouteCancelledReason) {
  //           const marker = createMarker(
  //             it.lat,
  //             it.lng,
  //             CancelMarker,
  //             undefined
  //           );
  //           mapObjects.push(marker);
  //         }
  //       });
  //       // add the wayPoints to the map
  //       route.wayPoints.forEach((it: any) => {
  //         const marker = createMarker(it.lat, it.lng, CircleDot, undefined);
  //         mapObjects.push(marker);
  //       });
  //       actualRouteObjectsRef.current = mapObjects;
  //       map?.addObjects(actualRouteObjectsRef.current);
  //     }

  //     // Displaying the calculated route on the map
  //     if (calculatedRouteVisible) {
  //       const routingParameters = calculateRoutingParams(
  //         data[0].lat,
  //         data[0].lng,
  //         route
  //       );

  //       const routeData = {
  //         avoidances: {
  //           toll: route.avoidTolls || false,
  //           dirtRoad: route.avoidUnpaved || false,
  //           ferry: route.avoidFerries || false,
  //           highway: route.avoidHighways || false,
  //           tunnel: route.avoidTunnels || false,
  //           propane: route.hasPropane || false,
  //         },
  //         rv_info: {
  //           height: routingParameters["vehicle[height]"] || 100,
  //           length: routingParameters["vehicle[length]"] || 100,
  //           weight: routingParameters["vehicle[grossWeight]"] || 100,
  //         },
  //         routes: [
  //           {
  //             origin: [...routingParameters.origin.split(",")],
  //             waypoints: [],
  //             destination: [...routingParameters.destination.split(",")],
  //           },
  //         ],
  //         metric: true,
  //         routing_type: routingParameters.routingType || "car",
  //         user_id: "0000000",
  //       };

  //       // Fetch routine calculations
  //       getRoute(routeData, false)
  //         .then((response) => {
  //           return response.data;
  //         })
  //         .then((json) => {
  //           const result = json.journeys[0];
  //           if (result.routes.length) {
  //             result.routes[0].sections.forEach((section: any) => {
  //               const linestring = H.geo.LineString.fromFlexiblePolyline(
  //                 section.polyline
  //               );
  //               const routeOutline = new H.map.Polyline(linestring, {
  //                 style: RouteOutlineStyle,
  //                 data: undefined,
  //               });
  //               const routeArrows = new H.map.Polyline(linestring, {
  //                 style: RouteArrowsStyle,
  //                 data: undefined,
  //               });
  //               const routeLine = new H.map.Group();
  //               routeLine.addObjects([routeOutline, routeArrows]);
  //               calculatedRouteObjectsRef.current.push(
  //                 routeLine,
  //                 startMarker,
  //                 endMarker
  //               );
  //             });
  //             // We need to make sure we are adding the created objects to the map
  //             map?.addObjects(calculatedRouteObjectsRef.current);
  //             // Update view port based on route line
  //             map?.getViewPort().setPadding(200, 200, 200, 200);
  //             map?.getViewModel().setLookAtData({
  //               bounds: new H.geo.Rect(
  //                 data[0].lat,
  //                 data[0].lng,
  //                 data[data.length - 1].lat,
  //                 data[data.length - 1].lng
  //               ),
  //             });
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     }

  //   };

  //   fetchAndUpdateRouteOnMap();

  //   // Cleanup function to remove objects from map on unmount
  //   return () => {
  //     removeMapObjects(actualRouteObjectsRef.current);
  //     removeMapObjects(calculatedRouteObjectsRef.current);
  //   };
  // }, [
  //   actualRouteVisible,
  //   calculatedRouteVisible,
  //   routeHash,
  //   route,
  //   fromLat,
  //   fromLng,
  //   destinationLat,
  //   destinationLng,
  //   removeMapObjects,
  //   map,
  // ]);

  const checkBoxItems: CheckBoxEntry[] = [
    {
      key: 1,
      label: "Actual Route",
      check: actualRouteVisible,
      onCheck: () => setActualRouteVisible(!actualRouteVisible),
    },
    {
      key: 2,
      label: "Calculated Route",
      check: calculatedRouteVisible,
      colorBox: pink[400],
      colorCheck: pink[200],
      onCheck: () => setCalculatedRouteVisible(!calculatedRouteVisible),
    },
  ];

  return (
    <>
      <CheckBoxMapMount checkBoxItems={checkBoxItems} />
      <LegendMapMount />
    </>
  );
};

export default RouteDetails;
