import React from "react";
import {
  Stack,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { clearAllRoutes, selectMapBoxRoute, selectRoute } from "stores/routing";
import RouteBox from "./RouteBox";
import RouteMapbox from "./RouteMapBoxBox";
import AvoidAreaToolButtons from "../AvoidArea/AvoidAreaToolButtons";
import { useNavigate } from "react-router-dom";
import BusZonesToolButtons from "tools/BusZones/BusZoneToolButtons";

export const RouteResultsSection: React.FC = () => {
  const [selectedRoute, setSelectedRoute] = React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  const carRoute = useAppSelector(selectRoute("car"));
  const truckRoute = useAppSelector(selectRoute("truck"));
  const navigate = useNavigate();

  const busRoute = useAppSelector(selectRoute("bus"));
  const carRouteNP = useAppSelector(selectRoute("carNP"));
  const truckRouteNP = useAppSelector(selectRoute("truckNP"));
  const busRouteNP = useAppSelector(selectRoute("busNP"));
  const mapBoxRoute = useAppSelector(selectMapBoxRoute());

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Button type="submit" variant="outlined">
          Route
        </Button>
        <Button onClick={() => dispatch(clearAllRoutes())} variant="outlined">
          Clear
        </Button>
      </Stack>
      <RouteBox route={carRoute} id="car" />
      <RouteBox route={truckRoute} id="truck" />
      <RouteBox route={busRoute} id="bus" />
      <RouteMapbox route={mapBoxRoute as any} id="mapBox" />
      <RouteBox route={carRouteNP} label="Car Route (No Params)" id="carNP" />
      <RouteBox
        route={truckRouteNP}
        label="Truck Route (No Params)"
        id="truckNP"
      />
      <RouteBox route={busRouteNP} label="Bus Route (No Params)" id="busNP" />
      <AvoidAreaToolButtons />
      <BusZonesToolButtons />

      <FormControl>
        <InputLabel>Jump to Sonar</InputLabel>
        <Select
          onChange={(e: SelectChangeEvent) => setSelectedRoute(e.target.value)}
        >
          <MenuItem value="car">car</MenuItem>
          <MenuItem value="truck">truck</MenuItem>
          <MenuItem value="bus">bus</MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          if (!selectedRoute) {
            alert("Please select a route to jump to Sonar with");
            return;
          }

          navigate(`/sonar?routeLine=${selectedRoute}&execute=true`);
        }}
      >
        Jump to Sonar
      </Button>
    </Stack>
  );
};
