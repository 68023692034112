import React from "react";
import {
  Stack,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const RouteParametersSection: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Controller
          name="height"
          control={control}
          render={({ field }) => <TextField label="height (ft)" {...field} />}
        />
        <Controller
          name="length"
          control={control}
          render={({ field }) => <TextField label="length (ft)" {...field} />}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Controller
          name="width"
          control={control}
          render={({ field }) => <TextField label="width (ft)" {...field} />}
        />
        <Controller
          name="weight"
          control={control}
          render={({ field }) => <TextField label="weight (lbs)" {...field} />}
        />
      </Stack>
      <FormGroup row>
        {[
          { name: "dirtRoad", label: "Dirt Roads" },
          { name: "tunnel", label: "Tunnels" },
          { name: "tollRoad", label: "Toll Roads" },
          { name: "highway", label: "Highways" },
          { name: "ferry", label: "Ferries" },
          { name: "propane", label: "Propane" },
          { name: "compareParams", label: "Compare Parameters" },
          { name: "approachWaypoints", label: "Test Approach Waypoints" },
          { name: "departureWaypoints", label: "Test Departure Waypoints" },
        ].map(({ name, label }) => (
          <FormControlLabel
            key={name}
            control={
              <Controller
                name={name}
                control={control}
                render={({ field }) => <Checkbox {...field} />}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </Stack>
  );
};
