import { HazardType } from "./CreateBlip";

export type Blip = {
  id: string;
  lat: number;
  lon: number;
  rv_height: number | null;
  rv_length: number | null;
  rv_type: String | null;
  pm_user_id: number | null;
  hazard_type: HazardType | null;
  rv_weight: number | null;
  low_clearance_height: number | null;
  weight_limit: number | null;
  still_there: Still_There[];
};

export type Still_There = {
  recorded_at: string;
  recorded_by: number;
  id: number;
  blip_id: string;
};

export const filterBlipsByHazardType = (
  blips: Blip[],
  selectedHazards: HazardType[]
): Blip[] => {
  if (selectedHazards.length === 0) return blips;
  return blips.filter((blip) =>
    selectedHazards.includes(blip.hazard_type as HazardType)
  );
};
