import React from "react";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { Blip } from "sonar/types/Blip";

interface UpdateLocationProps {
  oldBlip?: Blip;
  newBlip: Blip;
  showUpdate: boolean;
  setShowUpdate: (show: boolean) => void;
  onUpdate: (blip: Blip) => void;
}

const UpdateLocation: React.FC<UpdateLocationProps> = ({
  oldBlip,
  newBlip,
  showUpdate,
  setShowUpdate,
  onUpdate,
}) => {
  const handleClose = () => {
    setShowUpdate(false);
  };
  return (
    <Dialog
      open={showUpdate}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>Update Blip Location</DialogTitle>
      <DialogContent>
        <ReactDiffViewer
          oldValue={oldBlip}
          newValue={newBlip}
          splitView={true}
          compareMethod={DiffMethod.JSON}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onUpdate(newBlip);
            handleClose();
          }}
        >
          Submit Update
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLocation;
