import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "stores/AuthContext";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  getEditedAvoidArea,
  discardChanges,
  saveChanges,
  deleteAvoidArea,
  setEditedAvoidArea,
} from "stores/mapGrid";
import ConfirmationPopup from "support/components/Confirmation";
import {
  decodeBboxFromPolyline,
  formatPoint,
  getGridLocation,
  reverseGeocodeState,
} from "./utils.avoidarea";
import { IAvoidArea } from "stores/types";
import { selectOrigin, selectDestination } from "stores/routing";
import {
  ABSENT,
  DEFAULT_PRECISION,
  Position,
  encode as encodePolyline,
} from "util/flexible-polyline";
import { DatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";

interface IAvoidAreaFormValues {
  name: string;
  desc: string;
  height: number;
  length: number;
  weight: number;
  width: number;
  testCoords: boolean;
  reminderDate: Date | null;
}

const AvoidAreaForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const editedAvoidArea = useAppSelector(getEditedAvoidArea);
  const origin = useAppSelector(selectOrigin);
  const destination = useAppSelector(selectDestination);

  const { keycloak } = React.useContext(AuthContext);

  const defaultValues: IAvoidAreaFormValues = {
    desc: "",
    height: 0,
    length: 0,
    name: "New Avoid Area",
    weight: 0,
    width: 0,
    testCoords: false,
    reminderDate: null,
  };

  if (editedAvoidArea) {
    (Object.keys(defaultValues) as Array<keyof IAvoidAreaFormValues>).forEach(
      (key) => {
        if (key in editedAvoidArea) {
          const value = editedAvoidArea[key as keyof IAvoidArea];
          if (key === "reminderDate" && typeof value === "string") {
            (defaultValues[key] as Date) = parseISO(value);
          } else if (value) {
            (defaultValues[key] as typeof value) = value;
          }
        }
      }
    );
  }

  const { handleSubmit, control, reset } = useForm({ defaultValues });

  React.useEffect(() => {
    if (editedAvoidArea) {
      (Object.keys(defaultValues) as Array<keyof IAvoidAreaFormValues>).forEach(
        (key) => {
          if (key in editedAvoidArea) {
            const value = editedAvoidArea[key as keyof IAvoidArea];
            if (key === "reminderDate" && typeof value === "string") {
              (defaultValues[key] as Date) = parseISO(value);
            } else if (value) {
              (defaultValues[key] as typeof value) = value;
            }
          }
        }
      );
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedAvoidArea]);

  if (!editedAvoidArea) {
    return;
  }

  const { topLeft, bottomRight } = decodeBboxFromPolyline(
    editedAvoidArea.coordinates
  );

  const onSubmit = async (formData: IAvoidAreaFormValues) => {
    const newAvoidArea = { ...editedAvoidArea };

    const topLeftGrid = await getGridLocation(topLeft, keycloak?.token);
    const bottomRightGrid = await getGridLocation(bottomRight, keycloak?.token);

    const topLeftState = await reverseGeocodeState(topLeft);
    const bottomRightState = await reverseGeocodeState(bottomRight);

    if (topLeftState !== bottomRightState) {
      alert(
        "Avoid Areas must be in the same state. Top Left State: " +
          topLeftState +
          ", Bottom Right State: " +
          bottomRightState
      );
      return;
    }

    if (topLeftGrid !== bottomRightGrid) {
      alert(
        "Avoid Areas must be in the same zone. Top Left Zone: " +
          topLeftGrid +
          ", Bottom Right Zone: " +
          bottomRightGrid
      );
      return;
    }

    newAvoidArea.zone = topLeftGrid;
    newAvoidArea.state = topLeftState;
    newAvoidArea.name = formData.name;
    newAvoidArea.desc = formData.desc ?? "";
    newAvoidArea.height = Number(formData.height);
    newAvoidArea.length = Number(formData.length);
    newAvoidArea.weight = Number(formData.weight);
    newAvoidArea.width = Number(formData.width);

    if (formData.reminderDate)
      newAvoidArea.reminderDate = formData.reminderDate.toISOString();

    if (formData.testCoords) {
      if (origin && destination) {
        const payload = [
          [origin.lat, origin.lng],
          [destination.lat, destination.lng],
        ] as Position[];
        const encodedPayload = encodePolyline({
          precision: DEFAULT_PRECISION,
          thirdDim: ABSENT,
          thirdDimPrecision: 0,
          polyline: payload,
        });

        newAvoidArea.testCoordinates = encodedPayload;
      } else {
        alert("Please set an origin and destination first.");
        return;
      }
    }

    dispatch(setEditedAvoidArea(newAvoidArea));

    if (keycloak?.token) dispatch(saveChanges(keycloak.token));
  };

  const discardGridChanges = () => {
    dispatch(discardChanges());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <TextField
          label="Top Left Coordinate"
          disabled
          value={formatPoint(topLeft)}
        />
        <TextField
          label="Bottom Right Coordinate"
          disabled
          value={formatPoint(bottomRight)}
        />
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField label="Name" error={!!error} {...field} />
          )}
          rules={{ required: true }}
        />
        <Controller
          name="desc"
          control={control}
          render={({ field }) => (
            <TextField
              label="Description"
              multiline
              minRows={3}
              maxRows={3}
              {...field}
            />
          )}
        />
        <Controller
          name="height"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              type="number"
              label="Height (ft)"
              error={!!error}
              {...field}
            />
          )}
          rules={{ required: true, min: 0 }}
        />
        <Controller
          name="length"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              type="number"
              label="Length (ft)"
              error={!!error}
              {...field}
            />
          )}
          rules={{ required: true, min: 0 }}
        />
        <Controller
          name="width"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              type="number"
              label="Width (ft)"
              error={!!error}
              {...field}
            />
          )}
          rules={{ required: true, min: 0 }}
        />
        <Controller
          name="weight"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              type="number"
              label="Weight (lbs)"
              error={!!error}
              {...field}
            />
          )}
          rules={{ required: true, min: 0 }}
        />

        <Controller
          name="testCoords"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox disabled={!origin || !destination} {...field} />
              }
              label="Save origin/dest as test coords"
            />
          )}
        />

        <Controller
          name="reminderDate"
          control={control}
          render={({ field }) => (
            <DatePicker label="Remind Me On This Date" {...field} />
          )}
        />

        <React.Fragment>
          <Button variant="outlined" onClick={discardGridChanges}>
            Discard Changes
          </Button>
        </React.Fragment>

        <Button variant="outlined" type="submit">
          Save Changes
        </Button>
        <Button variant="outlined" onClick={() => setIsDeleting(true)}>
          Delete
        </Button>
        <ConfirmationPopup
          isOpen={isDeleting}
          onDelete={() => {
            if (keycloak?.token)
              dispatch(deleteAvoidArea(editedAvoidArea.id, keycloak?.token));
          }}
          onCancel={() => {
            setIsDeleting(false);
          }}
        />
      </Stack>
    </form>
  );
};

export default AvoidAreaForm;
