import React from "react";
import { selectRoute } from "stores/routing";
import { useAppSelector } from "stores/hooks";
import { selectVisibleRoutes } from "stores/ui";
import Polyline from "map/elements/Polyline";
import { RouteKey } from "stores/types";

const keyColor = {
  carNP: "rgba(0, 128, 255, 0.5)",
  car: "rgba(0, 128, 255, 0.7)",
  truckNP: "rgba(255, 0, 128, 0.5)",
  truck: "rgba(255, 0, 128, 0.7)",
  busNP: "rgba(128, 255, 0, 0.5)",
  bus: "rgba(128, 255, 0, 0.7)",
  mapBox: "rgba(43, 43, 43, 1)",
  mapBoxNP: "rgba(43, 43, 43, 1)",
};

const keyOffset = {
  carNP: 0,
  car: 0,
  truckNP: 2,
  truck: 2,
  busNP: 4,
  bus: 4,
  mapBox: 8,
  mapBoxNP: 8,
};

const GenericRoute: React.FC<{ id: RouteKey }> = ({ id }) => {
  const route = useAppSelector(selectRoute(id));
  const isRouteVisible = useAppSelector(selectVisibleRoutes(id));
  if (route && isRouteVisible) {
    const polylines = route.routes[0].sections.map((section) => (
      <Polyline
        positions={section.polyline}
        lineWidth={4}
        lineDash={[2, 6]}
        strokeColor={keyColor[id]}
        lineDashOffset={keyOffset[id]}
        key={section.id}
      />
    ));
    return <>{polylines}</>;
  }
  return null;
};

export default GenericRoute;
