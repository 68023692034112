import React from "react";
import { Route, Routes } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Drawer, DrawerHeader } from "./Sidebar/SidebarComponents";
import HomeScreen from "support/RouteSupport";
import SonarSupport from "sonar/SonarSupport";
import Dashboard from "./Sidebar/Dashboard";
import { OpenAvoidAreas } from "../tools/AvoidArea/OpenAvoidAreas";
import { SidebarContainer } from "./Sidebar/SidebarContainer";
import { AddAvoidAreas } from "../tools/AvoidArea/AddAvoidAreas";
import SidebarTopMenu from "./Sidebar/SidebarTopMenu";
import AvoidAreaList from "tools/AvoidArea/AvoidAreaList";
import BusZoneList from "tools/BusZones/BusZoneList";
import { OpenBusZones } from "tools/BusZones/OpenBusZones";
import { AddBusZones } from "tools/BusZones/AddBusZones";

const Sidebar: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer variant="permanent" open={open} anchor="right">
        <DrawerHeader>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <SidebarTopMenu setOpen={setOpen} />
        </DrawerHeader>
        <Divider />

        <Routes>
          <Route path="/" element={<SidebarContainer />} />

          <Route path="/avoid-areas" element={<OpenAvoidAreas />} />

          <Route path="/add-avoid-areas" element={<AddAvoidAreas />} />
          <Route
            path="/avoid-area-list"
            element={<AvoidAreaList open={open} setOpen={setOpen} />}
          />

          <Route path="/bus-zones" element={<OpenBusZones />} />

          <Route path="/add-bus-zones" element={<AddBusZones />} />
          <Route
            path="/bus-zone-list"
            element={<BusZoneList open={open} setOpen={setOpen} />}
          />

          <Route
            path="/support/:pmUserId?"
            element={<HomeScreen open={open} setOpen={setOpen} />}
          />
          <Route
            path="/support/routeHash/:routeHash?"
            element={<HomeScreen open={open} setOpen={setOpen} />}
          />
          <Route path="/sonar" element={<SonarSupport />} />
          <Route path="/missionControl" element={<Dashboard />} />
        </Routes>
      </Drawer>
    </>
  );
};

export default Sidebar;
