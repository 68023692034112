import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useHereMap } from "map/Context";
import React, { FC, useEffect } from "react";

export type CheckBoxEntry = {
  key: number;
  label: string;
  check: boolean;
  colorBox?: string;
  colorCheck?: string;
  onCheck: () => void;
};

type CheckBoxListProps = {
  checkBoxList: CheckBoxEntry[];
};

const CheckBoxList: FC<CheckBoxListProps> = function CheckBoxList({
  checkBoxList,
}: CheckBoxListProps) {
  return (
    <Box
      sx={{
        borderWidth: 1,
        borderColor: "black",
        bgcolor: "white",
        p: 1, // Assuming the theme's spacing unit is 8px, 1 unit * 8px = 8px
        zIndex: "tooltip", // MUI's zIndex values can be used for consistency, or simply use 1000
      }}
    >
      {checkBoxList.map((it) => (
        <Stack
          key={it.key}
          direction="row" // This sets the direction of the stack to horizontal
          spacing={0.5} // This sets the spacing between children; adjust based on your theme's spacing scale
          sx={{
            bgcolor: "white",
          }}
        >
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                checked={it.check}
                onChange={() => it.onCheck()}
                sx={{
                  color: it.colorBox,
                  "&.Mui-checked": {
                    color: it.colorCheck,
                  },
                }}
              />
            }
            label={it.label}
            labelPlacement="end"
          />
        </Stack>
      ))}
    </Box>
  );
};

const CheckBoxMapMount: React.FC<{
  checkBoxItems: CheckBoxEntry[];
}> = ({ checkBoxItems }) => {
  const checkboxesContainer = React.useRef(null);

  const { ui } = useHereMap();
  useEffect(() => {
    const checkboxes = new H.ui.Control();
    checkboxes.setAlignment(H.ui.LayoutAlignment.BOTTOM_RIGHT);
    ui?.addControl("checkboxes", checkboxes);
    if (checkboxesContainer.current)
      checkboxes.getElement()?.appendChild(checkboxesContainer.current);
    return () => {
      ui?.removeControl("checkboxes");
    };
  }, [ui]);

  return (
    <div ref={checkboxesContainer}>
      <CheckBoxList checkBoxList={checkBoxItems} />
    </div>
  );
};

export default CheckBoxMapMount;
