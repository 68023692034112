import Route from "./GenericRoute";
import MapBoxRoute from "./GenericRouteMapBox";

const Routes: React.FC = () => (
  <>
    <Route id="carNP" key="carNP" />
    <Route id="busNP" key="busNP"/>
    <Route id="truckNP" key="truckNP"/>
    <Route id="car" key="car"/>
    <Route id="bus" key="bus"/>
    <Route id="truck" key="truck"/>
    <MapBoxRoute id="mapBox"key="mapBox" />
  </>
);

export default Routes;
