import { Box, Typography } from "@mui/material";
import { useHereMap } from "map/Context";
import React, { FC, useEffect } from "react";
import { legendItems } from "../models/Legend";

export type LegendEntry = {
  key: number;
  color: string;
  title: string;
};

type LegendProps = {
  items: LegendEntry[];
};

const Legend: FC<LegendProps> = function Legend({ items }: LegendProps) {
  return (
    <Box
      sx={{
        borderWidth: 1,
        borderColor: "black",
        bgcolor: "white",
        p: 1, // Assuming the theme's spacing unit is 8px, 1 unit * 8px = 8px
      }}
    >
      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold" }}>
        Legend
      </Typography>
      {items.map((it) => (
        <Box
          key={it.key}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // To align items vertically in the center (if needed)
            padding: "4px", // Assuming 4px is the desired padding
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: it.color,
            }}
          />
          <Typography
            variant="caption"
            sx={{
              ml: 1, // Assuming the theme's spacing unit is 8px, 1 unit * 8px = 8px for margin start
            }}
          >
            {it.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const LegendMapMount: React.FC = () => {
  const legendsContainer = React.useRef(null);

  const { ui } = useHereMap();

  useEffect(() => {
    const legends = new H.ui.Control();
    legends.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);
    ui?.addControl("legends", legends);
    if (legendsContainer.current)
      legends.getElement()?.appendChild(legendsContainer.current);
    return () => {
      ui?.removeControl("legends");
    };
  }, [ui]);

  return (
    <div ref={legendsContainer}>
      <Legend items={legendItems} />
    </div>
  );
};

export default LegendMapMount;
