import Construction from "assets/svg/construction.svg";
import Flooding from "assets/svg/flooding.svg";
import HighWinds from "assets/svg/high-winds.svg";
import LowClearance from "assets/svg/low-clearance.svg";
import NoTrucks from "assets/svg/no-trucks.svg";
import RoadHazard from "assets/svg/road-hazard.svg";
import Snow from "assets/svg/snow.svg";
import SteepUp from "assets/svg/steep-up.svg";
import SteepDown from "assets/svg/steep-down.svg";
import Tunnel from "assets/svg/tunnel.svg";
import UnpavedRoad from "assets/svg/unpaved-road.svg";
import WeightLimit from "assets/svg/weight-limit.svg";
import LocationMarker from "assets/svg/location-marker.svg";

// <MenuItem value={"low_clearance"}>Low Clearance</MenuItem>
// <MenuItem value={"no_trucks"}>No Trucks</MenuItem>
// <MenuItem value={"tunnel"}>Tunnel</MenuItem>
// <MenuItem value={"unpaved_road"}>Unpaved Road</MenuItem>
// <MenuItem value={"narrow_road"}>Narrow Road</MenuItem>
// <MenuItem value={"steep_grade"}>Steep Grade</MenuItem>
// <MenuItem value={"weight_limit"}>Weight Limit</MenuItem>
// <MenuItem value={"road_hazard"}>Road Hazard</MenuItem>
// <MenuItem value={"high_winds"}>High Winds</MenuItem>
// <MenuItem value={"snow"}>Snow</MenuItem>
// <MenuItem value={"flooding"}>Flooding</MenuItem>
// <MenuItem value={"road_construction"}>Road Construction</MenuItem>

export const BlipIcons: { [key: string]: string } = {
  low_clearance: LowClearance,
  no_trucks: NoTrucks,
  tunnel: Tunnel,
  unpaved_road: UnpavedRoad,
  narrow_road: LocationMarker,
  steep_grade_up: SteepUp,
  steep_grade_down: SteepDown,
  weight_limit: WeightLimit,
  road_hazard: RoadHazard,
  high_winds: HighWinds,
  snow: Snow,
  flooding: Flooding,
  road_construction: Construction,
  default: LocationMarker,
};
