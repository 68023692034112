import {
  Button,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  addActiveApproachWaypoint,
  addEditedWaypointApproach,
  removeEditedWaypointApproach,
  selectActiveApproachWaypoint,
  selectEditedWaypointApproach,
  selectWaypointApproach,
  addActiveDepartureWaypoint,
  addEditedDepartureWaypoints,
  removeEditedDepartureWaypoints,
  selectActiveDepartureWaypoint,
  selectEditedDepartureWaypoints,
  selectDepartureWaypoints,
} from "stores/routing";
import { decode, encode } from "util/flexible-polyline";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { AuthContext } from "stores/AuthContext";
import axios from "axios";
import _ from "lodash";

const WaypointApproachTools: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const snackbarSeverity =
    snackbarMessage === "Error submitting departure waypoints!"
      ? "error"
      : "success";

  const SnackbarBlock = (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={10000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        variant="filled"
      >
        <AlertTitle>{_.capitalize(snackbarSeverity)}</AlertTitle>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  const waypointApproach = useAppSelector(selectWaypointApproach());
  const activeApproachWaypoint = useAppSelector(selectActiveApproachWaypoint());
  const editedWaypointApproach = useAppSelector(selectEditedWaypointApproach());
  const departureWaypoints = useAppSelector(selectDepartureWaypoints());
  const activeDepartureWaypoint = useAppSelector(
    selectActiveDepartureWaypoint()
  );
  const editedDepartureWaypoints = useAppSelector(
    selectEditedDepartureWaypoints()
  );
  const dispatch = useAppDispatch();

  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;

  if (!keycloak) {
    throw new Error("Keycloak object is not available");
  }

  const axiosInstance = axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? "https://cgr-api.dev-rvlife.com/parks/"
        : "https://cgr-api.rvlife.com/parks/", // I think this endpoint isn't set up in prod yet though.
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      accept: "application/x.rvparkreviews.v2+json",
    },
  });

  if (editedWaypointApproach) {
    const submitWaypointApproach = async () => {
      try {
        if (editedWaypointApproach.id) {
          // Assuming the presence of an id indicates an edit

          if (editedWaypointApproach.polyline === "BF") {
            await axiosInstance.delete(
              `${editedWaypointApproach.park_id}/waypoint-approaches/${editedWaypointApproach.id}`
            );
          } else {
            await axiosInstance.put(
              `${editedWaypointApproach.park_id}/waypoint-approaches/${editedWaypointApproach.id}`,
              editedWaypointApproach
            );
          }
        } else {
          // If no id, we assume this is a new waypoint approach
          if (editedWaypointApproach.polyline === "BF") {
            console.log(
              "No need to create an empty approach waypoints record."
            );
          } else {
            await axiosInstance.post(
              `${editedWaypointApproach.park_id}/waypoint-approaches`,
              editedWaypointApproach
            );
          }
        }
        setSnackbarMessage("Submission of waypoint approach successful!");
        setSnackbarOpen(true);
        dispatch(removeEditedWaypointApproach());
      } catch (error) {
        console.error("Error submitting waypoint approach:", error);
        setSnackbarMessage("Error submitting waypoint approach!");
        setSnackbarOpen(true);
      }
    };

    const decodedPolyline = decode(editedWaypointApproach.polyline);

    return (
      <>
        <Stack spacing={2} p={2} sx={{ width: "100%", flexWrap: "wrap" }}>
          <Stack direction="row">
            <Typography variant="h6">Approach Waypoints</Typography>
            <Button
              onClick={() => {
                dispatch(removeEditedWaypointApproach());
              }}
              variant="outlined"
              sx={{ ml: 2 }}
            >
              Leave Edit Mode
            </Button>
          </Stack>
          {decodedPolyline.polyline.map((point, i) => {
            const isActive = activeApproachWaypoint === i;
            const deleteWaypoint = () => {
              const newPolyline = decodedPolyline.polyline.slice();
              newPolyline.splice(i, 1);
              dispatch(
                addEditedWaypointApproach({
                  ...editedWaypointApproach,
                  polyline: encode({
                    polyline: newPolyline,
                    precision: 5, // Example precision, adjust accordingly,
                    thirdDim: 0,
                    thirdDimPrecision: 0,
                  }),
                })
              );
            };
            return (
              <Stack
                direction="row"
                spacing={2}
                p={2}
                sx={{ border: isActive ? 1 : 0, alignItems: "center" }}
                key={JSON.stringify(point)}
              >
                <Typography variant={"body2"}>{`${point[0].toFixed(
                  4
                )}, ${point[1].toFixed(4)}`}</Typography>
                <Tooltip title="Select">
                  <IconButton
                    onClick={() => {
                      dispatch(addActiveApproachWaypoint(i));
                    }}
                  >
                    <HighlightAltIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={deleteWaypoint}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          })}
          <Button variant="outlined" onClick={submitWaypointApproach}>
            Submit
          </Button>
        </Stack>
        {SnackbarBlock}
      </>
    );
  }

  if (editedDepartureWaypoints) {
    const submitDepartureWaypoints = async () => {
      try {
        if (editedDepartureWaypoints.id) {
          // Assuming the presence of an id indicates an edit

          if (editedDepartureWaypoints.polyline === "BF") {
            await axiosInstance.delete(
              `${editedDepartureWaypoints.park_id}/waypoint-departures/${editedDepartureWaypoints.id}`
            );
          } else {
            await axiosInstance.put(
              `${editedDepartureWaypoints.park_id}/waypoint-departures/${editedDepartureWaypoints.id}`,
              editedDepartureWaypoints
            );
          }
        } else {
          // If no id, we assume this is a new departure waypoint
          if (editedDepartureWaypoints.polyline === "BF") {
            console.log("No need to create empty departure waypoints record.");
          } else {
            await axiosInstance.post(
              `${editedDepartureWaypoints.park_id}/waypoint-departures`,
              editedDepartureWaypoints
            );
          }
        }
        setSnackbarMessage("Submission of departure waypoints successful!");
        setSnackbarOpen(true);
        dispatch(removeEditedDepartureWaypoints());
      } catch (error) {
        console.error("Error submitting departure waypoints:", error);
        setSnackbarMessage("Error submitting departure waypoints!");
        setSnackbarOpen(true);
      }
    };

    const decodedPolyline = decode(editedDepartureWaypoints.polyline);

    return (
      <>
        <Stack spacing={2} p={2} sx={{ width: "100%", flexWrap: "wrap" }}>
          <Stack direction="row">
            <Typography variant="h6">Departure Waypoints</Typography>
            <Button
              onClick={() => {
                dispatch(removeEditedDepartureWaypoints());
              }}
              variant="outlined"
              sx={{ ml: 2 }}
            >
              Leave Edit Mode
            </Button>
          </Stack>
          {decodedPolyline.polyline.map((point, i) => {
            const isActive = activeDepartureWaypoint === i;
            const deleteWaypoint = () => {
              const newPolyline = decodedPolyline.polyline.slice();
              newPolyline.splice(i, 1);
              dispatch(
                addEditedDepartureWaypoints({
                  ...editedDepartureWaypoints,
                  polyline: encode({
                    polyline: newPolyline,
                    precision: 5, // Example precision, adjust accordingly,
                    thirdDim: 0,
                    thirdDimPrecision: 0,
                  }),
                })
              );
            };
            return (
              <Stack
                direction="row"
                spacing={2}
                p={2}
                sx={{ border: isActive ? 1 : 0, alignItems: "center" }}
                key={JSON.stringify(point)}
              >
                <Typography variant={"body2"}>{`${point[0].toFixed(
                  4
                )}, ${point[1].toFixed(4)}`}</Typography>
                <Tooltip title="Select">
                  <IconButton
                    onClick={() => {
                      dispatch(addActiveDepartureWaypoint(i));
                    }}
                  >
                    <HighlightAltIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={deleteWaypoint}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          })}
          <Button variant="outlined" onClick={submitDepartureWaypoints}>
            Submit
          </Button>
        </Stack>
        {SnackbarBlock}
      </>
    );
  }

  let waypointApproachField: JSX.Element | null = null;
  let departureWaypointsField: JSX.Element | null = null;

  if (waypointApproach) {
    const decodedPolyline = decode(waypointApproach.polyline);
    waypointApproachField = (
      <Stack spacing={2} p={2} sx={{ width: "100%", flexWrap: "wrap" }}>
        <Stack direction="row">
          <Typography variant="h6">Approach Waypoints</Typography>
          <Button
            onClick={() => {
              dispatch(addEditedWaypointApproach(waypointApproach));
            }}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Edit Mode
          </Button>
        </Stack>
        {decodedPolyline.polyline.map((point, i) => {
          const isActive = activeApproachWaypoint === i;
          return (
            <Stack
              direction="row"
              spacing={2}
              p={2}
              sx={{ border: isActive ? 1 : 0, alignItems: "center" }}
              key={JSON.stringify(point)}
              onClick={() => {
                dispatch(addActiveApproachWaypoint(i));
              }}
            >
              <Typography variant={"body2"}>{`${point[0].toFixed(
                4
              )}, ${point[1].toFixed(4)}`}</Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  }

  if (departureWaypoints) {
    const decodedPolyline = decode(departureWaypoints.polyline);
    departureWaypointsField = (
      <Stack spacing={2} p={2} sx={{ width: "100%", flexWrap: "wrap" }}>
        <Stack direction="row">
          <Typography variant="h6">Departure Waypoints</Typography>
          <Button
            onClick={() => {
              dispatch(addEditedDepartureWaypoints(departureWaypoints));
            }}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Edit Mode
          </Button>
        </Stack>
        {decodedPolyline.polyline.map((point, i) => {
          const isActive = activeDepartureWaypoint === i;
          return (
            <Stack
              direction="row"
              spacing={2}
              p={2}
              sx={{ border: isActive ? 1 : 0, alignItems: "center" }}
              key={JSON.stringify(point)}
              onClick={() => {
                dispatch(addActiveDepartureWaypoint(i));
              }}
            >
              <Typography variant={"body2"}>{`${point[0].toFixed(
                4
              )}, ${point[1].toFixed(4)}`}</Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  }

  return (
    <>
      {waypointApproachField}
      {departureWaypointsField}
      {SnackbarBlock}
    </>
  );
};

export default WaypointApproachTools;
