import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Link } from "@mui/material";
import { useHereMap } from "map/Context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StillThereDialog from "./SonarModals/StillThereDialog";
import { Blip } from "sonar/types/Blip";
import { metricToImperial } from "util/conversion";

interface BlipsTableProps {
  blips: Blip[];
}

// header style
let headerStyle = {
  backgroundColor: "#119758",
  color: "white",
};

const BlipsTable: React.FC<BlipsTableProps> = ({ blips }) => {
  const { map } = useHereMap();
  const [selectedBlip, setSelectedBlip] = useState<Blip | null>(null);
  const [stillThereBlip, setStillThereBlip] = useState<Blip | undefined>(
    undefined
  );
  const [selectedId, setSelectedId] = useState<string | null>(null); // Added state for selectedId

  if (blips.length === 0) {
    return <div></div>;
  }

  const jumpToBlip = (blip: Blip) => {
    let group = new H.map.Group();
    map?.getObjects(true).forEach((object) => {
      if (object instanceof H.map.Marker) {
        const data = object.getData();
        if (data && data.id === blip.id) {
          group.addObjects([object]);
          map.addObject(group);

          map?.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
            zoom: 15,
          });
        }
      }
    });
  };

  const handleOpenModal = (blip: Blip) => {
    setSelectedBlip(blip);
  };

  const handleCloseModal = () => {
    setSelectedBlip(null);
  };

  const handleRowClick = (id: string) => {
    setSelectedId(id);
  };
  const handleOpenStillThereModal = (blip: Blip) => {
    setStillThereBlip(blip);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={headerStyle}>
            <Tooltip title="Hover to View Latitude and Longitude, Click to jump to blip">
              <span>
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ marginLeft: "4px" }}
                />
                ID
              </span>
            </Tooltip>
          </TableCell>
          <TableCell style={headerStyle}>Hazard Type</TableCell>
          <TableCell style={headerStyle}>RV INFO</TableCell>
          <TableCell style={headerStyle}>STILL THERE ENTRIES</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {blips.map((blip) => (
          <TableRow
            key={blip.id}
            style={selectedId === blip.id ? { backgroundColor: "yellow" } : {}} // Highlight the row if selectedId matches blip.id
            onClick={() => handleRowClick(blip.id)} // Call handleRowClick when the row is clicked
          >
            <TableCell>
              <Tooltip title={`Latitude: ${blip.lat}, Longitude: ${blip.lon}`}>
                <Link
                  href="#"
                  onClick={() => {
                    jumpToBlip(blip);
                  }}
                >
                  id
                </Link>
              </Tooltip>
            </TableCell>
            <TableCell>{blip.hazard_type}</TableCell>
            <TableCell>
              <Button onClick={() => handleOpenModal(blip)}>RV Info</Button>
            </TableCell>

            <TableCell>
              {blip.still_there.length > 0 ? (
                <Button onClick={() => handleOpenStillThereModal(blip)}>
                  Entries
                </Button>
              ) : (
                <Button onClick={() => handleOpenStillThereModal(blip)}>
                  NO Entries
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <Dialog open={selectedBlip !== null} onClose={handleCloseModal}>
        <DialogTitle>RV Info</DialogTitle>
        <DialogContent>
          {selectedBlip && (
            <>
              <DialogContentText>
                RV Height: {selectedBlip.rv_height} cm (
                {metricToImperial(selectedBlip.rv_height, "cm")})
              </DialogContentText>
              <DialogContentText>
                RV Length: {selectedBlip.rv_length} cm (
                {metricToImperial(selectedBlip.rv_length, "cm")})
              </DialogContentText>
              <DialogContentText>
                RV Type: {selectedBlip.rv_type}
              </DialogContentText>
              <DialogContentText>
                PM User ID: {selectedBlip.pm_user_id}
              </DialogContentText>
              <DialogContentText>
                RV Weight: {selectedBlip.rv_weight} kg (
                {metricToImperial(selectedBlip.rv_weight, "kg")})
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
      <StillThereDialog
        stillThereBlip={stillThereBlip}
        onClose={() => setStillThereBlip(undefined)}
      />
    </Table>
  );
};

export default BlipsTable;
