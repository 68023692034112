import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const SidebarTopMenu: React.FC<{ setOpen(value: boolean): void }> = ({
  setOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        variant="contained"
        sx={{ m: 2 }}
      >
        Atlas Tools
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Button
            component={Link}
            to="/"
            variant="text"
            onClick={() => {
              setOpen(false);
              handleClose();
            }}
            fullWidth
          >
            Route Tools
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={Link}
            to="/support"
            variant="text"
            onClick={() => {
              setOpen(true);
              handleClose();
            }}
            fullWidth
          >
            Support Tool
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={Link}
            to="/sonar"
            variant="text"
            onClick={() => {
              handleClose();
            }}
            fullWidth
          >
            Sonar
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={Link}
            to="/avoid-areas"
            variant="text"
            onClick={() => {
              handleClose();
            }}
            fullWidth
          >
            Avoid Areas
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SidebarTopMenu;
