import React, { useEffect } from "react";
import H from "@here/maps-api-for-javascript";
import { Layers, MapObjectEvent } from "../types";
import { useGroupContext } from "map/Group";

export type GeoShapeProps = {
  testID?: string;
  shape: H.map.GeoShape | undefined;
  data?: Record<string, unknown>;
  layer?: Layers;
  zIndex?: number;
  volatile?: boolean;
  fillColor?: string;
  lineDash?: number[];
  lineWidth?: number;
  lineDashOffset?: number;
  strokeColor?: string;
  draggable?: boolean;
  onPointerEnter?: (event: MapObjectEvent) => void;
  onPointerLeave?: (event: MapObjectEvent) => void;
  onPointerDown?: (event: MapObjectEvent) => void;
  onPointerMove?: (event: MapObjectEvent) => void;
  onTap?: (event: MapObjectEvent) => void;
};

function handleEventHandler(
  shape: H.map.GeoShape | undefined,
  event: string,
  handler: ((event: MapObjectEvent) => void) | undefined
) {
  if (!shape || !handler) {
    return;
  }

  shape.addEventListener(event, handler);

  return () => {
    shape.removeEventListener(event, handler);
  };
}

const GeoShape: React.FC<GeoShapeProps> = ({
  shape,
  layer = undefined,
  data = undefined,
  volatile = undefined,
  zIndex = undefined,
  fillColor = undefined,
  lineDash = undefined,
  lineWidth = undefined,
  lineDashOffset = undefined,
  strokeColor = undefined,
  draggable = undefined,
  onPointerEnter = undefined,
  onPointerLeave = undefined,
  onPointerDown = undefined,
  onPointerMove = undefined,
  onTap = undefined,
  testID = undefined,
}: GeoShapeProps) => {
  const { addObject, removeObject } = useGroupContext();

  useEffect(() => {
    if (!addObject || !removeObject || !shape) {
      return;
    }

    addObject(shape, layer);

    return () => {
      removeObject(shape, layer);
      shape.dispose();
    };
  }, [addObject, layer, removeObject, shape]);

  useEffect(() => {
    if (!shape) {
      return;
    }
    shape.setData({
      ...shape.getData(),
      ...data,
    });
  }, [shape, data]);

  useEffect(() => {
    if (!shape) {
      return;
    }
    shape.setData({
      ...shape.getData(),
      testID,
    });
  }, [shape, testID]);

  useEffect(() => {
    if (!shape || zIndex === undefined) {
      return;
    }

    shape.setZIndex(zIndex);
  }, [shape, zIndex]);

  useEffect(() => {
    if (!shape) {
      return;
    }

    shape.setVolatility(volatile);
  }, [shape, volatile]);

  useEffect(
    () => handleEventHandler(shape, "pointerleave", onPointerLeave),
    [shape, onPointerLeave]
  );

  useEffect(
    () => handleEventHandler(shape, "pointerenter", onPointerEnter),
    [shape, onPointerEnter]
  );

  useEffect(
    () => handleEventHandler(shape, "pointerdown", onPointerDown),
    [shape, onPointerDown]
  );

  useEffect(
    () => handleEventHandler(shape, "pointermove", onPointerMove),
    [shape, onPointerMove]
  );

  useEffect(() => handleEventHandler(shape, "tap", onTap), [shape, onTap]);

  useEffect(() => {
    if (!shape || draggable === undefined) {
      return;
    }
    shape.draggable = draggable;
  }, [shape, draggable]);

  useEffect(() => {
    if (!shape) {
      return;
    }

    let style = shape.getStyle();

    if (fillColor !== undefined) {
      style = style.getCopy({ fillColor });
    }

    if (lineDash !== undefined) {
      style = style.getCopy({ lineDash });
    }

    if (lineWidth !== undefined) {
      style = style.getCopy({ lineWidth });
    }

    if (strokeColor !== undefined) {
      style = style.getCopy({ strokeColor });
    }

    if (lineDashOffset !== undefined) {
      style = style.getCopy({ lineDashOffset });
    }

    shape.setStyle(style);
  }, [shape, fillColor, lineDash, lineWidth, lineDashOffset, strokeColor]);

  return null;
};

export default GeoShape;
